@mixin mediaw($media)
{
  @media only screen and (max-width: $media) {
    @content
  }
}

@mixin mediawmore($media) 
{
  @media only screen and (min-width: $media) { 
    @content
  } 
} 

@mixin mediah($media)
{
  @media only screen and (max-height: $media) {
    @content
  }
}
