.header {
    background-repeat: no-repeat;
    background-image: url(../img/header-bg.jpg);
    background-position: right top;
    background-size: cover;
    height: 768px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 1920px;
    margin: 0 auto;
    transition: background-image .3s;
    @include mediaw(1199px) {
        background-position-x: calc(100% + 50px);
    }
    @include mediaw(1150px) {
        background-position-x: calc(100% + 100px);
    }
    @include mediaw(768px) {
        background: #85894A;
        height: initial;
        padding-bottom: 20px;
    }
    &__title {
        font-family: $fontOpenSansSemibold;
        font-size: 28px;
        line-height: 48px;
        color: #fff;
        position: relative;
        @include mediaw(768px) {
            display: none;
        }
        &::after {
            content: " ";
            display: block;
            width: 70px;
            height: 1px;
            background-color: #fff;
            position: absolute;
            bottom: -17px;
            left: 0;
            transition: background-color .3s;
            @include mediaw(768px) {
                display: none;
            }
        }
    }
    &__description {
        font-family: $fontLobsterRegular;
        font-size: 65px;
        line-height: 96px;
        color: #fff;
        margin-top: 41px;
        @include mediaw(950px) {
            font-size: 44px;
        }
        @include mediaw(768px) {
            display: none;
        }
    }
    .image-block {
        display: none;
        width: calc(100% + 30px);
        transform: translateX(-15px);
        &.tablet {
            @include mediaw(768px) {
                display: block;
            }
        }
        img {
            display: block;
            margin: 0 auto;
            max-width: 100%;
        }
    }
    &__list {
        margin-top: 50px;
        font-family: $fontOpenSansRegular;
        font-size: 22px;
        line-height: 40px;
        color: #fff;
        transition: color .3s;
        @include mediaw(992px) {
            text-shadow: 2px 2px 2px rgba(0,0,0,0.75);
        }
        @include mediaw(768px) {
            text-shadow: initial;
            line-height: 38px;
            margin: 10px auto 0;
            max-width: 420px;
        }
        @include mediaw(480px) {
            font-size: 14px;
            max-width: 320px;
        }
        @include mediaw(330px) {
            font-size: 13px;
        }
        li {
            padding-left: 50px;
            background-repeat: no-repeat;
            background-position: 11px center;
            &:first-child {
                background-image: url(../img/battery.svg);
                background-position-x: 10px;
            }
            &:nth-child(2) {
                background-image: url(../img/lightbulb.svg);
            }
            &:last-child {
                background-image: url(../img/fahrenheit.svg);
                background-position-x: 13px;
            }
        }
    }
    &__price {
        margin-top: 70px;
        max-width: 300px;
        display: flex;
        justify-content: center;
        font-family: $fontOpenSansBold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        // @include mediaw(480px) {
        //     font-size: 14px;
        //     line-height: 22px;
        // }
        .old, .new {
            // width: calc(50% - 20px);
            color: #fff;
            span {
                font-size: 36px;
                line-height: 40px;
                display: inline-block;
                margin-top: 15px;
                color: #fff;
                transition: color .3s;
                @include mediaw(351px) {
                    font-size: 31px;
                }
            }
        }
        .old span {
            font-family: $fontFuturaMedium;
            position: relative;
            &::after {
                content: " ";
                display: block;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 4px;
                width: 100%;
                background-color: #f75252;
            }
        }
        .new span {
            font-family: $fontFuturaBold;
            @include mediaw(330px) {
                font-size: 30px;
            }
        }
    }
    &__order {
        margin-top: 50px;
        width: 300px;
        height: 50px;
        border-radius: 25px;
        background-color: #fc1d25;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontOpenSansBold;
        font-size: 20px;
        line-height: 40px;
        color: #fff;
        box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            margin: 15px auto 0;
            max-width: 100%;
        }
    }
    .switcher {
        position: absolute;
        left: 50%;
        bottom: 33px;
        transform: translateX(-50%);
        width: 40px;
        height: 3px;
        background-color: #fff;
        cursor: pointer;
        transition: background-color .3s;
        z-index: 2;
        @include mediaw(1299px) {
            display: none;
        }
        .labels {
            position: absolute;
            top: 12px;
            left: -48px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            width: 132px;
            color: #C0BFBF;
            font-family: $fontOpenSansLight;
            font-size: 12px;
        }
        .circle {
            width: 20px;
            height: 20px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
            background-color: #212121;
            position: absolute;
            left: -10px;
            top: -9px;
            border-radius: 50%;
            cursor: pointer;
            transition: background-color .3s, left .3s;
        }
        &::before {
            content: " ";
            display: block;
            width: 27px;
            height: 27px;
            background-repeat: no-repeat;
            background-image: url(../img/light-icon.svg);
            background-size: cover;
            position: absolute;
            top: -13px;
            left: -50px;
        }
        &::after {
            content: " ";
            display: block;
            width: 12.4px;
            height: 15px;
            background-repeat: no-repeat;
            background-image: url(../img/dark-icon.svg);
            background-size: cover;
            position: absolute;
            right: -40px;
            top: -7px;
            transition: opacity .3s;
            @include mediaw(480px) {
                transform: rotate(-90deg);
            }
        }
    }
}