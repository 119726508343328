.pulse {
    position: relative;
    &::after {
        content: " ";
        animation: pulse 2.5s infinite;
        position: absolute;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        width: 50px;
        height: 50px;
        border-radius: 50%;
    }
  }
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 red;
    }
    50% {
        box-shadow: 0 0 0 188px red;
    }
    100% {
        box-shadow: 0 0 0 0 red;
    }
  }

.animate {
    padding-top: 100px;
    overflow: hidden;
    @include mediaw(1111px) {
        padding-top: 50px;
    }
    &__title {
        @include mediaw(1111px) {
            margin-bottom: 25px !important;
        }
    }
    &__row {
        display: flex;
        @include mediaw(1111px) {
            flex-wrap: wrap;
        }
    }
    .left {
        width: calc(100% - 385px);
        @include mediaw(1111px) {
            width: 705px;
            margin: 0 auto;
        }
        @include mediaw(740px) {
            max-width: 100%;
        }
        .heart-block {
            height: 425px;
            position: relative;
            .image-block {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                max-width: 210px;
                max-height: 205px;
                opacity: 0;
                transition: opacity 1s linear;
                img {
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            .left-hand, .right-hand {
                position: absolute;
                top: calc(50% + 15px);
                transform: translateY(-50%);
                max-width: 330px;
                max-height: 265px;
                z-index: 1;
                opacity: 0;
                transition: opacity .1s, transform .5s;
                transition-delay: 0.5s;
                @include mediaw(740px) {
                    max-width: 231px;
                    max-height: 185.5px;
                    top: calc(50% + 5px);
                }
                img {
                    display: block;
                    max-width: 100%;
                    margin: 0 auto;
                }
            }
            .left-hand {
                left: 7px;
                transform: translateX(-100%);
                @include mediaw(740px) {
                    left: 60px;
                }
                @include mediaw(705px) {
                    left: 40px;
                }
                @include mediaw(666px) {
                    left: 20px;
                }
                @include mediaw(625px) {
                    left: 0;
                }
                @include mediaw(585px) {
                    left: -20px;
                }
                @include mediaw(545px) {
                    left: -40px;
                }
                @include mediaw(505px) {
                    left: -60px;
                }
                @include mediaw(470px) {
                    left: -80px;
                }
                @include mediaw(400px) {
                    left: -85px;
                }
                @include mediaw(365px) {
                    left: -95px;
                }
            }
            .right-hand {
                right: 7px;
                transform: translateX(100%);
                @include mediaw(740px) {
                    right: 60px;
                }
                @include mediaw(705px) {
                    right: 40px;
                }
                @include mediaw(666px) {
                    right: 20px;
                }
                @include mediaw(625px) {
                    right: 0;
                }
                @include mediaw(585px) {
                    right: -20px;
                }
                @include mediaw(545px) {
                    right: -40px;
                }
                @include mediaw(505px) {
                    right: -60px;
                }
                @include mediaw(470px) {
                    right: -80px;
                }
                @include mediaw(400px) {
                    right: -85px;
                }
                @include mediaw(365px) {
                    right: -95px;
                }
            }
            .first-circle, .second-circle, .third-circle {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                background-color: #ff0000;
                opacity: 0;
                transition: opacity .5s;
                border-radius: 50%;
            }
            .first-circle {
                width: 270px;
                height: 270px;
                transition-delay: 1s;
            }
            .second-circle {
                width: 350px;
                height: 350px;
                transition-delay: 2s;
            }
            .third-circle {
                width: 425px;
                height: 425px;
                transition-delay: 3s;
            }
            &.active {
                .image-block {
                    opacity: 1;
                }
                .left-hand, .right-hand {
                    opacity: 1;
                }
                .left-hand {
                    transform: translateX(0) translateY(-50%);
                }
                .right-hand {
                    transform: translateX(0) translateY(-50%);
                }
                .first-circle, .second-circle, .third-circle {
                    opacity: 0.1;
                }
            }
        }
    }
    .right {
        width: 385px;
        @include mediaw(1111px) {
            width: 100%;
            margin-top: 50px;
        }
        .image-block {
            img {
                display: block;
                max-width: 100%;
                margin: 0 auto;
            }
        }
    }
    .price__row {
        margin-top: 50px;
    }
}

.description {
    padding: 100px 0;
    @include mediaw(768px) {
        padding: 50px 0;
    }
    &__title {
        margin-bottom: 40px !important;
    }
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include mediaw(1092px) {
            justify-content: space-around;
        }
        @include mediaw(600px) {
            justify-content: space-between;
        }
    }
    &__item {
        width: calc(50% - 15px);
        .image-block {
            img {
                display: block;
                max-width: 100%;
                max-height: 100%;
                margin: 0 auto;
            }
        }
        .text {
            text-align: center;
            font-family: $fontFuturaBold;
            font-size: 32px;
            line-height: 40px;
            color: #404040;
            margin: 25px 0 30px;
            @include mediaw(768px) {
                font-size: 23px;
                line-height: 30px;
            }
            @include mediaw(576px) {
                font-size: 18px;
                line-height: 22px;
            }
            @include mediaw(480px) {
                margin-top: 5px;
                font-size: 12px;
                line-height: 15px;
            }
        }
    }
}

.information {
    font-family: $fontOpenSansRegular;
    font-size: 24px;
    line-height: 30px;
    color: #404040;
    @include mediaw(768px) {
        font-size: 18px;
        line-height: 26px;
        padding: 50px 0;
    }
    @include mediaw(480px) {
        padding: 25px 0;
    }
    &__title {
        max-width: 450px;
        @include mediaw(1199px) {
            max-width: initial;
        }
    }
    .container {
        background-repeat: no-repeat;
        background-image: url(../img/information-bg.jpg);
        background-position: right center;
        padding: 35px 15px;
        @include mediaw(1199px) {
            padding: 850px 15px 0;
            background-position: center top;
        }
        @include mediaw(576px) {
            background-size: contain;
            padding: 800px 15px 0;
        }
        @include mediaw(520px) {
            padding: 750px 15px 0;
        }   
        @include mediaw(500px) {
            padding: 700px 15px 0;
        }
        @include mediaw(460px) {
            padding: 650px 15px 0;
        }
        @include mediaw(400px) {
            padding: 580px 15px 0;
        }
        @include mediaw(375px) {
            padding: 545px 15px 0;
        }
        @include mediaw(355px) {
            padding: 510px 15px 0;
        }
        @include mediaw(340px) {
            padding: 485px 15px 0;
        }
        div {
            max-width: 555px;
            @include mediaw(1199px) {
                max-width: initial;
            }
            p {
                &:not(:first-child) {
                    margin-top: 25px;
                    @include mediaw(768px) {
                        margin-top: 12.5px;
                    }
                }
            }
        }
    }
    .desctop {
        @include mediaw(768px) {
            display: none;
        }
    }
    .mobile {
        display: none !important;
        @include mediaw(768px) {
            display: block !important;
        }
    }
}

.look {
    background-color: #ffe5e5;
    margin-bottom: 100px;
    padding: 40px 0;
    @include mediaw(480px) {
        padding: 20px 0;
        margin-bottom: 50px;
    }
    &__title {
        margin-bottom: 30px !important;
        @include mediaw(480px) {
            margin-bottom: 15px !important;
        }
    }
    &__video {
        width: 850px;
        margin: 0 auto;
        height: 480px;
        @include mediaw(900px) {
            width: 100%;
        }
        @include mediaw(685px) {
            height: 360px;
        }
        @include mediaw(525px) {
            height: 205px;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .price__row {
        margin-top: 30px;
        background-color: rgba(0,0,0,0.07);
        @include mediaw(480px) {
            margin-top: 15px;
        }
    }
}

.reviews {
    padding: 95px 0 100px;
    background-color: #fff;
    @include mediaw(768px) {
        padding: 75px 0;
    }
    @include mediaw(480px) {
        padding: 35px 0;
    }
    &__title {
        margin-bottom: 30px !important;
    }
    &__slider--wrapper {
        position: relative;
        @include mediaw(1300px) {
            padding: 0 45px;
        }
        @include mediaw(480px) {
            padding: 0 20px;
        }
        .reviews__buttons {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -60px;
            width: calc(100% + 120px);
            display: flex;
            justify-content: space-between;
            z-index: 1;
            @include mediaw(1300px) {
                left: -10px;
                width: calc(100% + 20px);
            }
            @include mediaw(480px) {
                left: -5px;
                width: calc(100% + 10px);
            }
            .reviews__buttons-prev, .reviews__buttons-next {
                width: 30px;
                height: 50px;
                background-repeat: no-repeat;
                background-size: cover;
                cursor: pointer;
                @include mediaw(480px) {
                    width: 15px;
                    height: 25px;
                }
            }
            .reviews__buttons-prev {
                background-image: url(../img/arrow-left.svg);
            }
            .reviews__buttons-next {
                background-image: url(../img/arrow-right.svg);
            }
        }
    }
    &__slider {
        z-index: 1;
    }
    .slick-list {
        margin: 0 -15px;
    }
    .slick-dots {
        @include mediaw(480px) {
            bottom: -10px;
        }
        li {
            width: 15px;
            height: 15px;
            margin: 0 10px;
            @include mediaw(480px) {
                margin: 0 5px;
            }
            button {
                width: 15px;
                height: 15px;
                &::before {
                    font-size: 15px;
                    width: 15px;
                    height: 15px;
                    color: #cacaca;
                    opacity: 1;
                    @include mediaw(480px) {
                        font-size: 13px;
                    }
                }
            }
            &.slick-active {
                button {
                    &::before {
                        color: #c73540;
                    }
                }
            }
        }
    }
    &__slide {
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        border-radius: 10px;
        margin: 0 15px 20px;
        padding: 0 0 40px;
        position: relative;
        @include mediaw(480px) {
            padding-bottom: 15px;
        }
        .image-block {
            overflow: hidden;
            border-radius: 10px 10px 0 0;
            height: 270px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .name, .text {
            padding: 0 30px;
            @include mediaw(480px) {
                padding: 0 15px;
            }
        }
        .name {
            margin-top: 40px;
            color: #f21d80;
            font-family: $fontOpenSansRegular;
            font-size: 28px;
            line-height: 40px;
            // text-align: center;
            @include mediaw(480px) {
                margin-top: 15px;
                font-size: 24px;
                line-height: 36px;
            }
        }
        .text {
            margin-top: 10px;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 24px;
            color: #404040;
            @include mediaw(480px) {
                margin-top: 10px;
                font-size: 14px;
            }
        }
    }
    .price__row {
        margin: 45px auto 0;
    }
}

.characteristic {
    background-color: #fff;
    padding: 95px 0 25px;
    @include mediaw(480px) {
        padding: 50px 0;
    }
    &__title {
        margin-bottom: 40px !important;
    }
    .list-row {
        display: flex;
        justify-content: space-between;
        @include mediaw(991px) {
            flex-wrap: wrap;
        }
    }
    &__list {
        width: calc(50% - 15px);
        font-family: $fontOpenSansRegular;
        font-size: 16px;
        line-height: 22px;
        color: #404040;
        @include mediaw(991px) {
            width: 100%;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 20px;
        }
        li {
            display: flex;
            justify-content: space-between;
            padding: 4px 15px;
            background-color: #f4f4f4;
            &:nth-child(2n) {
                background-color: #fff;
            }
            p:first-child {
                width: 50%;
            }
            p:last-child {
                width: 50%;
                text-align: right;
            }
            &.warning {
                color: #c73540;
                p:first-child {
                    width: 0;
                }
                p:last-child {
                    width: 100%;
                }
            }
        }
    }
    .mirrors-row {
        display: flex;
        justify-content: space-around;
        margin: 100px auto 0;
        max-width: 855px;
        @include mediaw(480px) {
            margin: 50px auto 0;
        }
        &__item {
            width: 33.33334%;
            img {
                max-width: 100%;
                display: block;
                margin: 0 auto;
            }
            a {
                display: block;
                margin-top: 30px;
            }
        }
    }
    .price__row {
        margin-top: 30px;
    }
}

.images {
    &__row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        width: 25%;
        @include mediaw(768px) {
            width: 50%;
        }
        img {
            display: block;
            width: 100%;
            max-width: 100%;
            max-height: 100%;
            margin: 0 auto;
        }
    }
}

.form {
    background-repeat: no-repeat;
    background-image: url(../img/form-bg.jpg);
    background-size: cover;
    background-color: #fff;
    color: #fff;
    padding: 35px 0;
    @include mediaw(1050px) {
        background-position-x: -50px;
    }
    @include mediaw(1000px) {
        background-position-x: -110px;
    }
    @include mediaw(940px) {
        background-position-x: -130px;
    }
    @include mediaw(768px) {
        background-position-x: calc(100% + 100px);
    }
    &__block {
        border-radius: 10px;
        box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
        background-color: #ffffff;
        padding: 35px 30px 30px;
        max-width: 360px;
        margin-left: auto;
        @include mediaw(768px) {
            margin: 0 auto;
        }
        @include mediaw(480px) {
            padding: 17.5px 15px 20px;
        }
    }
    &__title {
        font-size: 28px !important;
        margin-bottom: 0 !important;
        color: #531230 !important;
    }
    .container {
        position: relative;
        z-index: 1;
    }
    form {
        display: block;
        .input-wrapper {
            position: relative;
            width: 100%;
            &:last-child {
                @include mediaw(480px) {
                    margin-top: 15px;
                }
            }
        }
        .input-wrapper .floating-label {
            position: absolute;
            pointer-events: none;
            top: 30px;
            left: 0;
            transition: 0.2s ease all;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            color: #212121;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 38px;
            }
        }
        .input-wrapper input:focus ~ .floating-label,
        .input-wrapper input:not(:focus):valid ~ .floating-label{
            top: 0;
            font-size: 14px;
        }
        .inputs-wrapper {
            display: flex;
            justify-content: space-between;
            @include mediaw(480px) {
                flex-wrap: wrap;
            }
        }
        input {
            display: block;
            width: 100%;
            &:not([type="submit"]) {
                height: 70px;
                background-color: transparent;
                border-bottom: 1px solid #212121;
                font-family: $fontFuturaBold;
                font-size: 25px;
                line-height: 40px;
                color: #212121;
                padding-top: 25px;
                @include mediaw(480px) {
                    padding-top: 15px;
                    height: 75px;
                }
            }
            &[type="submit"] {
                width: 260px;
                height: 50px;
                border-radius: 25px;
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
                background-color: #c73540;
                font-size: 20px;
                color: #fff;
                font-family: $fontOpenSansRegular;
                cursor: pointer;
                margin: 30px auto 0;
                @include mediaw(600px) {
                    margin: 15px auto 0;
                }
                @include mediaw(341px) {
                    max-width: 100%;
                }
            }
            &[type="radio"] {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }
        }
        .color--wrapper {
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 40px;
            color: #212121;
            margin: 15px auto 0;
            text-align: center;
            max-width: 222px;
            @include mediaw(480px) {
                font-size: 13px;
                line-height: 20px;
            }
            div {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
            .color-item {
                display: block;
                width: 68px;
                height: 68px;
                border-radius: 50%;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                cursor: pointer;
                &-name {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 13px;
                    background-color: #fff;
                    top: 0px;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    border: 1px solid #000;
                    line-height: 14px;
                    padding: 3px;
                    border-radius: 10px;
                    opacity: 0;
                    transition: opacity .3s;
                    &::after {
                        content: " ";
                        display: block;
                        width: 7px;
                        height: 7px;
                        position: absolute;
                        left: 50%;
                        bottom: -5px;
                        transform: translateX(-50%) rotate(-45deg);
                        border-left: 1px solid #000;
                        border-bottom: 1px solid #000;
                        background-color: #fff;
                    }
                }
                &:hover .color-item-name {
                    opacity: 1;
                }
                @include mediaw(480px) {
                    padding: 5px 0 5px 30px;
                }
                @include mediaw(480px) {
                    width: 50px;
                    height: 50px;
                }
                &:first-child {
                    background-color: #FEDCDB;
                }
                &:nth-child(2) {
                    background-color: #E0E0E6;
                }
                &:nth-child(3) {
                    background-color: #B81B20;
                }
                input:checked ~ .radioBtn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translateX(-50%) translateY(-50%);
                    width: 15px;
                    height: 15px;
                    border-radius: 50%;
                    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.69);
                    background-color: #ffffff;
                    @include mediaw(480px) {
                        width: 10px;
                        height: 10px;
                    }
                }
            }
        }
    }
    &__price {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 24px;
        position: relative;
        left: -30px;
        background-color: #fee4e4;
        width: calc(100% + 60px);
        padding: 15px 20px;
        text-align: center;
        @include mediaw(768px) {
            margin: 20px auto 0;
        }
        @include mediaw(480px) {
            font-size: 14px;
            line-height: 22px;
            left: -15px;
            width: calc(100% + 30px);
            padding: 10px 25px;
        }
        .old, .new {
            color: rgba(83, 18, 48, 0.6);
            span {
                font-size: 30px;
                line-height: 40px;
                display: inline-block;
                color: #212121;
                margin-top: 20px;
                @include mediaw(480px) {
                    margin-top: 10px;
                }
                @include mediaw(374px) {
                    font-size: 29px;
                    line-height: initial;
                }
            }
        }
        .old span {
            font-family: $fontFuturaLight;
            position: relative;
            &::after {
                content: " ";
                display: block;
                position: absolute;
                left: 0;
                top: 22px;
                height: 2px;
                width: 100%;
                background-color: #f75252;
            }
        }
        .new {
            text-align: right;
            span {
                font-family: $fontFuturaBold;
                color: #531230;
                @include mediaw(341px) {
                    font-size: 26px;
                }
            }
        }
    }
}

.advantages {
    background-color: #72494b;
    color: #fff;
    padding: 40px 0;
    .container {
        display: flex;
        justify-content: space-between;
        @include mediaw(768px) {
            flex-wrap: wrap;
        }
    }
    &__item {
        width: calc(33.33334% - 30px);
        padding-left: 120px;
        position: relative;
        background-repeat: no-repeat;
        @include mediaw(768px) {
            width: 100%;
            padding-left: 90px;
        }
        &:first-child {
            background-image: url(../img/delivery-icon.svg);
            background-position: 20px 35px;
            @include mediaw(768px) {
                background-position: 12px 23px;
                background-size: 55px;
            }
        }
        &:nth-child(2) {
            background-image: url(../img/wallet-icon.svg);
            background-position: 27px 23px;
            @include mediaw(768px) {
                background-position: 17px 13px;
                background-size: 44px;
                margin-top: 20px;
            }
        }
        &:last-child {
            background-image: url(../img/package-icon.svg);
            background-position: 32px 28px;
            @include mediaw(768px) {
                background-position: 20px 16px;
                margin-top: 20px;
            }
        }
        &::before {
            content: " ";
            display: block;
            width: 100px;
            height: 100px;
            border-radius: 50%;
            border: solid 2px rgba(255, 255, 255, 0.4);
            position: absolute;
            left: 0;
            top: 0;
            @include mediaw(768px) {
                width: 75px;
                height: 75px;
            }
        }
        &-title {
            font-family: $fontOpenSansRegular;
            font-size: 24px;
            line-height: 40px;
            @include mediaw(480px) {
                font-size: 21px;
                line-height: 38px;
            }
        }
        &-description {
            margin-top: 10px;
            font-family: $fontOpenSansRegular;
            font-size: 16px;
            line-height: 22px;
            @include mediaw(480px) {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}