*, *::before, *::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; }

a {
  color: inherit;
  text-decoration: none; }

ul {
  list-style: none; }

input, button {
  border: none; }
  input:focus, button:focus {
    outline: none; }

body.overflow-active {
  overflow: hidden; }

@font-face {
  font-family: 'Futura PT Light';
  src: url("../fonts/FuturaPT-Light.eot");
  src: url("../fonts/FuturaPT-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Light.woff") format("woff"), url("../fonts/FuturaPT-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Light';
  src: url("../fonts/OpenSans-Light.eot");
  src: url("../fonts/OpenSans-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Light.woff") format("woff"), url("../fonts/OpenSans-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Regular';
  src: url("../fonts/OpenSans-Regular.eot");
  src: url("../fonts/OpenSans-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Regular.woff") format("woff"), url("../fonts/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Bold';
  src: url("../fonts/FuturaPT-Bold.eot");
  src: url("../fonts/FuturaPT-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Bold.woff") format("woff"), url("../fonts/FuturaPT-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Book';
  src: url("../fonts/FuturaPT-Book.eot");
  src: url("../fonts/FuturaPT-Book.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Book.woff") format("woff"), url("../fonts/FuturaPT-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Futura PT Medium';
  src: url("../fonts/FuturaPT-Medium.eot");
  src: url("../fonts/FuturaPT-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/FuturaPT-Medium.woff") format("woff"), url("../fonts/FuturaPT-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Semibold';
  src: url("../fonts/OpenSans-SemiBold.eot");
  src: url("../fonts/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-SemiBold.woff") format("woff"), url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans Bold';
  src: url("../fonts/OpenSans-Bold.eot");
  src: url("../fonts/OpenSans-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/OpenSans-Bold.woff") format("woff"), url("../fonts/OpenSans-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Lobster Regular';
  src: url("../fonts/Lobster.eot");
  src: url("../fonts/Lobster.eot?#iefix") format("embedded-opentype"), url("../fonts/Lobster.woff") format("woff"), url("../fonts/Lobster.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

.header {
  background-repeat: no-repeat;
  background-image: url(../img/header-bg.jpg);
  background-position: right top;
  background-size: cover;
  height: 768px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-transition: background-image .3s;
  -o-transition: background-image .3s;
  transition: background-image .3s; }
  @media only screen and (max-width: 1199px) {
    .header {
      background-position-x: calc(100% + 50px); } }
  @media only screen and (max-width: 1150px) {
    .header {
      background-position-x: calc(100% + 100px); } }
  @media only screen and (max-width: 768px) {
    .header {
      background: #85894A;
      height: initial;
      padding-bottom: 20px; } }
  .header__title {
    font-family: "Open Sans Semibold";
    font-size: 28px;
    line-height: 48px;
    color: #fff;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .header__title {
        display: none; } }
    .header__title::after {
      content: " ";
      display: block;
      width: 70px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      bottom: -17px;
      left: 0;
      -webkit-transition: background-color .3s;
      -o-transition: background-color .3s;
      transition: background-color .3s; }
      @media only screen and (max-width: 768px) {
        .header__title::after {
          display: none; } }
  .header__description {
    font-family: "Lobster Regular";
    font-size: 65px;
    line-height: 96px;
    color: #fff;
    margin-top: 41px; }
    @media only screen and (max-width: 950px) {
      .header__description {
        font-size: 44px; } }
    @media only screen and (max-width: 768px) {
      .header__description {
        display: none; } }
  .header .image-block {
    display: none;
    width: calc(100% + 30px);
    -webkit-transform: translateX(-15px);
        -ms-transform: translateX(-15px);
            transform: translateX(-15px); }
    @media only screen and (max-width: 768px) {
      .header .image-block.tablet {
        display: block; } }
    .header .image-block img {
      display: block;
      margin: 0 auto;
      max-width: 100%; }
  .header__list {
    margin-top: 50px;
    font-family: "Open Sans Regular";
    font-size: 22px;
    line-height: 40px;
    color: #fff;
    -webkit-transition: color .3s;
    -o-transition: color .3s;
    transition: color .3s; }
    @media only screen and (max-width: 992px) {
      .header__list {
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.75); } }
    @media only screen and (max-width: 768px) {
      .header__list {
        text-shadow: initial;
        line-height: 38px;
        margin: 10px auto 0;
        max-width: 420px; } }
    @media only screen and (max-width: 480px) {
      .header__list {
        font-size: 14px;
        max-width: 320px; } }
    @media only screen and (max-width: 330px) {
      .header__list {
        font-size: 13px; } }
    .header__list li {
      padding-left: 50px;
      background-repeat: no-repeat;
      background-position: 11px center; }
      .header__list li:first-child {
        background-image: url(../img/battery.svg);
        background-position-x: 10px; }
      .header__list li:nth-child(2) {
        background-image: url(../img/lightbulb.svg); }
      .header__list li:last-child {
        background-image: url(../img/fahrenheit.svg);
        background-position-x: 13px; }
  .header__price {
    margin-top: 70px;
    max-width: 300px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Bold";
    font-size: 24px;
    line-height: 24px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .header__price {
        margin: 20px auto 0; } }
    .header__price .old, .header__price .new {
      color: #fff; }
      .header__price .old span, .header__price .new span {
        font-size: 36px;
        line-height: 40px;
        display: inline-block;
        margin-top: 15px;
        color: #fff;
        -webkit-transition: color .3s;
        -o-transition: color .3s;
        transition: color .3s; }
        @media only screen and (max-width: 351px) {
          .header__price .old span, .header__price .new span {
            font-size: 31px; } }
    .header__price .old span {
      font-family: "Futura PT Medium";
      position: relative; }
      .header__price .old span::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        height: 4px;
        width: 100%;
        background-color: #f75252; }
    .header__price .new span {
      font-family: "Futura PT Bold"; }
      @media only screen and (max-width: 330px) {
        .header__price .new span {
          font-size: 30px; } }
  .header__order {
    margin-top: 50px;
    width: 300px;
    height: 50px;
    border-radius: 25px;
    background-color: #fc1d25;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Bold";
    font-size: 20px;
    line-height: 40px;
    color: #fff;
    -webkit-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2);
            box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.2); }
    @media only screen and (max-width: 768px) {
      .header__order {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .header__order {
        margin: 15px auto 0;
        max-width: 100%; } }
  .header .switcher {
    position: absolute;
    left: 50%;
    bottom: 33px;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 40px;
    height: 3px;
    background-color: #fff;
    cursor: pointer;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
    z-index: 2; }
    @media only screen and (max-width: 1299px) {
      .header .switcher {
        display: none; } }
    .header .switcher .labels {
      position: absolute;
      top: 12px;
      left: -48px;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      width: 132px;
      color: #C0BFBF;
      font-family: "Open Sans Light";
      font-size: 12px; }
    .header .switcher .circle {
      width: 20px;
      height: 20px;
      -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
              box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
      background-color: #212121;
      position: absolute;
      left: -10px;
      top: -9px;
      border-radius: 50%;
      cursor: pointer;
      -webkit-transition: background-color .3s, left .3s;
      -o-transition: background-color .3s, left .3s;
      transition: background-color .3s, left .3s; }
    .header .switcher::before {
      content: " ";
      display: block;
      width: 27px;
      height: 27px;
      background-repeat: no-repeat;
      background-image: url(../img/light-icon.svg);
      background-size: cover;
      position: absolute;
      top: -13px;
      left: -50px; }
    .header .switcher::after {
      content: " ";
      display: block;
      width: 12.4px;
      height: 15px;
      background-repeat: no-repeat;
      background-image: url(../img/dark-icon.svg);
      background-size: cover;
      position: absolute;
      right: -40px;
      top: -7px;
      -webkit-transition: opacity .3s;
      -o-transition: opacity .3s;
      transition: opacity .3s; }
      @media only screen and (max-width: 480px) {
        .header .switcher::after {
          -webkit-transform: rotate(-90deg);
              -ms-transform: rotate(-90deg);
                  transform: rotate(-90deg); } }

.pulse {
  position: relative; }
  .pulse::after {
    content: " ";
    -webkit-animation: pulse 2.5s infinite;
            animation: pulse 2.5s infinite;
    position: absolute;
    top: calc(50% - 25px);
    left: calc(50% - 25px);
    width: 50px;
    height: 50px;
    border-radius: 50%; }

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 red;
            box-shadow: 0 0 0 0 red; }
  50% {
    -webkit-box-shadow: 0 0 0 188px red;
            box-shadow: 0 0 0 188px red; }
  100% {
    -webkit-box-shadow: 0 0 0 0 red;
            box-shadow: 0 0 0 0 red; } }

@keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 red;
            box-shadow: 0 0 0 0 red; }
  50% {
    -webkit-box-shadow: 0 0 0 188px red;
            box-shadow: 0 0 0 188px red; }
  100% {
    -webkit-box-shadow: 0 0 0 0 red;
            box-shadow: 0 0 0 0 red; } }

.animate {
  padding-top: 100px;
  overflow: hidden; }
  @media only screen and (max-width: 1111px) {
    .animate {
      padding-top: 50px; } }
  @media only screen and (max-width: 1111px) {
    .animate__title {
      margin-bottom: 25px !important; } }
  .animate__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    @media only screen and (max-width: 1111px) {
      .animate__row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .animate .left {
    width: calc(100% - 385px); }
    @media only screen and (max-width: 1111px) {
      .animate .left {
        width: 705px;
        margin: 0 auto; } }
    @media only screen and (max-width: 740px) {
      .animate .left {
        max-width: 100%; } }
    .animate .left .heart-block {
      height: 425px;
      position: relative; }
      .animate .left .heart-block .image-block {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        max-width: 210px;
        max-height: 205px;
        opacity: 0;
        -webkit-transition: opacity 1s linear;
        -o-transition: opacity 1s linear;
        transition: opacity 1s linear; }
        .animate .left .heart-block .image-block img {
          display: block;
          max-width: 100%;
          margin: 0 auto; }
      .animate .left .heart-block .left-hand, .animate .left .heart-block .right-hand {
        position: absolute;
        top: calc(50% + 15px);
        -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
                transform: translateY(-50%);
        max-width: 330px;
        max-height: 265px;
        z-index: 1;
        opacity: 0;
        -webkit-transition: opacity .1s, -webkit-transform .5s;
        transition: opacity .1s, -webkit-transform .5s;
        -o-transition: opacity .1s, transform .5s;
        transition: opacity .1s, transform .5s;
        transition: opacity .1s, transform .5s, -webkit-transform .5s;
        -webkit-transition-delay: 0.5s;
             -o-transition-delay: 0.5s;
                transition-delay: 0.5s; }
        @media only screen and (max-width: 740px) {
          .animate .left .heart-block .left-hand, .animate .left .heart-block .right-hand {
            max-width: 231px;
            max-height: 185.5px;
            top: calc(50% + 5px); } }
        .animate .left .heart-block .left-hand img, .animate .left .heart-block .right-hand img {
          display: block;
          max-width: 100%;
          margin: 0 auto; }
      .animate .left .heart-block .left-hand {
        left: 7px;
        -webkit-transform: translateX(-100%);
            -ms-transform: translateX(-100%);
                transform: translateX(-100%); }
        @media only screen and (max-width: 740px) {
          .animate .left .heart-block .left-hand {
            left: 60px; } }
        @media only screen and (max-width: 705px) {
          .animate .left .heart-block .left-hand {
            left: 40px; } }
        @media only screen and (max-width: 666px) {
          .animate .left .heart-block .left-hand {
            left: 20px; } }
        @media only screen and (max-width: 625px) {
          .animate .left .heart-block .left-hand {
            left: 0; } }
        @media only screen and (max-width: 585px) {
          .animate .left .heart-block .left-hand {
            left: -20px; } }
        @media only screen and (max-width: 545px) {
          .animate .left .heart-block .left-hand {
            left: -40px; } }
        @media only screen and (max-width: 505px) {
          .animate .left .heart-block .left-hand {
            left: -60px; } }
        @media only screen and (max-width: 470px) {
          .animate .left .heart-block .left-hand {
            left: -80px; } }
        @media only screen and (max-width: 400px) {
          .animate .left .heart-block .left-hand {
            left: -85px; } }
        @media only screen and (max-width: 365px) {
          .animate .left .heart-block .left-hand {
            left: -95px; } }
      .animate .left .heart-block .right-hand {
        right: 7px;
        -webkit-transform: translateX(100%);
            -ms-transform: translateX(100%);
                transform: translateX(100%); }
        @media only screen and (max-width: 740px) {
          .animate .left .heart-block .right-hand {
            right: 60px; } }
        @media only screen and (max-width: 705px) {
          .animate .left .heart-block .right-hand {
            right: 40px; } }
        @media only screen and (max-width: 666px) {
          .animate .left .heart-block .right-hand {
            right: 20px; } }
        @media only screen and (max-width: 625px) {
          .animate .left .heart-block .right-hand {
            right: 0; } }
        @media only screen and (max-width: 585px) {
          .animate .left .heart-block .right-hand {
            right: -20px; } }
        @media only screen and (max-width: 545px) {
          .animate .left .heart-block .right-hand {
            right: -40px; } }
        @media only screen and (max-width: 505px) {
          .animate .left .heart-block .right-hand {
            right: -60px; } }
        @media only screen and (max-width: 470px) {
          .animate .left .heart-block .right-hand {
            right: -80px; } }
        @media only screen and (max-width: 400px) {
          .animate .left .heart-block .right-hand {
            right: -85px; } }
        @media only screen and (max-width: 365px) {
          .animate .left .heart-block .right-hand {
            right: -95px; } }
      .animate .left .heart-block .first-circle, .animate .left .heart-block .second-circle, .animate .left .heart-block .third-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
            -ms-transform: translateX(-50%) translateY(-50%);
                transform: translateX(-50%) translateY(-50%);
        background-color: #ff0000;
        opacity: 0;
        -webkit-transition: opacity .5s;
        -o-transition: opacity .5s;
        transition: opacity .5s;
        border-radius: 50%; }
      .animate .left .heart-block .first-circle {
        width: 270px;
        height: 270px;
        -webkit-transition-delay: 1s;
             -o-transition-delay: 1s;
                transition-delay: 1s; }
      .animate .left .heart-block .second-circle {
        width: 350px;
        height: 350px;
        -webkit-transition-delay: 2s;
             -o-transition-delay: 2s;
                transition-delay: 2s; }
      .animate .left .heart-block .third-circle {
        width: 425px;
        height: 425px;
        -webkit-transition-delay: 3s;
             -o-transition-delay: 3s;
                transition-delay: 3s; }
      .animate .left .heart-block.active .image-block {
        opacity: 1; }
      .animate .left .heart-block.active .left-hand, .animate .left .heart-block.active .right-hand {
        opacity: 1; }
      .animate .left .heart-block.active .left-hand {
        -webkit-transform: translateX(0) translateY(-50%);
            -ms-transform: translateX(0) translateY(-50%);
                transform: translateX(0) translateY(-50%); }
      .animate .left .heart-block.active .right-hand {
        -webkit-transform: translateX(0) translateY(-50%);
            -ms-transform: translateX(0) translateY(-50%);
                transform: translateX(0) translateY(-50%); }
      .animate .left .heart-block.active .first-circle, .animate .left .heart-block.active .second-circle, .animate .left .heart-block.active .third-circle {
        opacity: 0.1; }
  .animate .right {
    width: 385px; }
    @media only screen and (max-width: 1111px) {
      .animate .right {
        width: 100%;
        margin-top: 50px; } }
    .animate .right .image-block img {
      display: block;
      max-width: 100%;
      margin: 0 auto; }
  .animate .price__row {
    margin-top: 50px; }

.description {
  padding: 100px 0; }
  @media only screen and (max-width: 768px) {
    .description {
      padding: 50px 0; } }
  .description__title {
    margin-bottom: 40px !important; }
  .description__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    @media only screen and (max-width: 1092px) {
      .description__row {
        -ms-flex-pack: distribute;
            justify-content: space-around; } }
    @media only screen and (max-width: 600px) {
      .description__row {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
  .description__item {
    width: calc(50% - 15px); }
    .description__item .image-block img {
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: 0 auto; }
    .description__item .text {
      text-align: center;
      font-family: "Futura PT Bold";
      font-size: 32px;
      line-height: 40px;
      color: #404040;
      margin: 25px 0 30px; }
      @media only screen and (max-width: 768px) {
        .description__item .text {
          font-size: 23px;
          line-height: 30px; } }
      @media only screen and (max-width: 576px) {
        .description__item .text {
          font-size: 18px;
          line-height: 22px; } }
      @media only screen and (max-width: 480px) {
        .description__item .text {
          margin-top: 5px;
          font-size: 12px;
          line-height: 15px; } }

.information {
  font-family: "Open Sans Regular";
  font-size: 24px;
  line-height: 30px;
  color: #404040; }
  @media only screen and (max-width: 768px) {
    .information {
      font-size: 18px;
      line-height: 26px;
      padding: 50px 0; } }
  @media only screen and (max-width: 480px) {
    .information {
      padding: 25px 0; } }
  .information__title {
    max-width: 450px; }
    @media only screen and (max-width: 1199px) {
      .information__title {
        max-width: initial; } }
  .information .container {
    background-repeat: no-repeat;
    background-image: url(../img/information-bg.jpg);
    background-position: right center;
    padding: 35px 15px; }
    @media only screen and (max-width: 1199px) {
      .information .container {
        padding: 850px 15px 0;
        background-position: center top; } }
    @media only screen and (max-width: 576px) {
      .information .container {
        background-size: contain;
        padding: 800px 15px 0; } }
    @media only screen and (max-width: 520px) {
      .information .container {
        padding: 750px 15px 0; } }
    @media only screen and (max-width: 500px) {
      .information .container {
        padding: 700px 15px 0; } }
    @media only screen and (max-width: 460px) {
      .information .container {
        padding: 650px 15px 0; } }
    @media only screen and (max-width: 400px) {
      .information .container {
        padding: 580px 15px 0; } }
    @media only screen and (max-width: 375px) {
      .information .container {
        padding: 545px 15px 0; } }
    @media only screen and (max-width: 355px) {
      .information .container {
        padding: 510px 15px 0; } }
    @media only screen and (max-width: 340px) {
      .information .container {
        padding: 485px 15px 0; } }
    .information .container div {
      max-width: 555px; }
      @media only screen and (max-width: 1199px) {
        .information .container div {
          max-width: initial; } }
      .information .container div p:not(:first-child) {
        margin-top: 25px; }
        @media only screen and (max-width: 768px) {
          .information .container div p:not(:first-child) {
            margin-top: 12.5px; } }
  @media only screen and (max-width: 768px) {
    .information .desctop {
      display: none; } }
  .information .mobile {
    display: none !important; }
    @media only screen and (max-width: 768px) {
      .information .mobile {
        display: block !important; } }

.look {
  background-color: #ffe5e5;
  margin-bottom: 100px;
  padding: 40px 0; }
  @media only screen and (max-width: 480px) {
    .look {
      padding: 20px 0;
      margin-bottom: 50px; } }
  .look__title {
    margin-bottom: 30px !important; }
    @media only screen and (max-width: 480px) {
      .look__title {
        margin-bottom: 15px !important; } }
  .look__video {
    width: 850px;
    margin: 0 auto;
    height: 480px; }
    @media only screen and (max-width: 900px) {
      .look__video {
        width: 100%; } }
    @media only screen and (max-width: 685px) {
      .look__video {
        height: 360px; } }
    @media only screen and (max-width: 525px) {
      .look__video {
        height: 205px; } }
    .look__video iframe {
      display: block;
      width: 100%;
      height: 100%; }
  .look .price__row {
    margin-top: 30px;
    background-color: rgba(0, 0, 0, 0.07); }
    @media only screen and (max-width: 480px) {
      .look .price__row {
        margin-top: 15px; } }

.reviews {
  padding: 95px 0 100px;
  background-color: #fff; }
  @media only screen and (max-width: 768px) {
    .reviews {
      padding: 75px 0; } }
  @media only screen and (max-width: 480px) {
    .reviews {
      padding: 35px 0; } }
  .reviews__title {
    margin-bottom: 30px !important; }
  .reviews__slider--wrapper {
    position: relative; }
    @media only screen and (max-width: 1300px) {
      .reviews__slider--wrapper {
        padding: 0 45px; } }
    @media only screen and (max-width: 480px) {
      .reviews__slider--wrapper {
        padding: 0 20px; } }
    .reviews__slider--wrapper .reviews__buttons {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      left: -60px;
      width: calc(100% + 120px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      z-index: 1; }
      @media only screen and (max-width: 1300px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -10px;
          width: calc(100% + 20px); } }
      @media only screen and (max-width: 480px) {
        .reviews__slider--wrapper .reviews__buttons {
          left: -5px;
          width: calc(100% + 10px); } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        width: 30px;
        height: 50px;
        background-repeat: no-repeat;
        background-size: cover;
        cursor: pointer; }
        @media only screen and (max-width: 480px) {
          .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev, .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
            width: 15px;
            height: 25px; } }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-prev {
        background-image: url(../img/arrow-left.svg); }
      .reviews__slider--wrapper .reviews__buttons .reviews__buttons-next {
        background-image: url(../img/arrow-right.svg); }
  .reviews__slider {
    z-index: 1; }
  .reviews .slick-list {
    margin: 0 -15px; }
  @media only screen and (max-width: 480px) {
    .reviews .slick-dots {
      bottom: -10px; } }
  .reviews .slick-dots li {
    width: 15px;
    height: 15px;
    margin: 0 10px; }
    @media only screen and (max-width: 480px) {
      .reviews .slick-dots li {
        margin: 0 5px; } }
    .reviews .slick-dots li button {
      width: 15px;
      height: 15px; }
      .reviews .slick-dots li button::before {
        font-size: 15px;
        width: 15px;
        height: 15px;
        color: #cacaca;
        opacity: 1; }
        @media only screen and (max-width: 480px) {
          .reviews .slick-dots li button::before {
            font-size: 13px; } }
    .reviews .slick-dots li.slick-active button::before {
      color: #c73540; }
  .reviews__slide {
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    border-radius: 10px;
    margin: 0 15px 20px;
    padding: 0 0 40px;
    position: relative; }
    @media only screen and (max-width: 480px) {
      .reviews__slide {
        padding-bottom: 15px; } }
    .reviews__slide .image-block {
      overflow: hidden;
      border-radius: 10px 10px 0 0;
      height: 270px; }
      .reviews__slide .image-block img {
        display: block;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
           object-fit: cover; }
    .reviews__slide .name, .reviews__slide .text {
      padding: 0 30px; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name, .reviews__slide .text {
          padding: 0 15px; } }
    .reviews__slide .name {
      margin-top: 40px;
      color: #f21d80;
      font-family: "Open Sans Regular";
      font-size: 28px;
      line-height: 40px; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .name {
          margin-top: 15px;
          font-size: 24px;
          line-height: 36px; } }
    .reviews__slide .text {
      margin-top: 10px;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 24px;
      color: #404040; }
      @media only screen and (max-width: 480px) {
        .reviews__slide .text {
          margin-top: 10px;
          font-size: 14px; } }
  .reviews .price__row {
    margin: 45px auto 0; }

.characteristic {
  background-color: #fff;
  padding: 95px 0 25px; }
  @media only screen and (max-width: 480px) {
    .characteristic {
      padding: 50px 0; } }
  .characteristic__title {
    margin-bottom: 40px !important; }
  .characteristic .list-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 991px) {
      .characteristic .list-row {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .characteristic__list {
    width: calc(50% - 15px);
    font-family: "Open Sans Regular";
    font-size: 16px;
    line-height: 22px;
    color: #404040; }
    @media only screen and (max-width: 991px) {
      .characteristic__list {
        width: 100%; } }
    @media only screen and (max-width: 480px) {
      .characteristic__list {
        font-size: 14px;
        line-height: 20px; } }
    .characteristic__list li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      padding: 4px 15px;
      background-color: #f4f4f4; }
      .characteristic__list li:nth-child(2n) {
        background-color: #fff; }
      .characteristic__list li p:first-child {
        width: 50%; }
      .characteristic__list li p:last-child {
        width: 50%;
        text-align: right; }
      .characteristic__list li.warning {
        color: #c73540; }
        .characteristic__list li.warning p:first-child {
          width: 0; }
        .characteristic__list li.warning p:last-child {
          width: 100%; }
  .characteristic .mirrors-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    margin: 100px auto 0;
    max-width: 855px; }
    @media only screen and (max-width: 480px) {
      .characteristic .mirrors-row {
        margin: 50px auto 0; } }
    .characteristic .mirrors-row__item {
      width: 33.33334%; }
      .characteristic .mirrors-row__item img {
        max-width: 100%;
        display: block;
        margin: 0 auto; }
      .characteristic .mirrors-row__item a {
        display: block;
        margin-top: 30px; }
  .characteristic .price__row {
    margin-top: 30px; }

.images__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.images__item {
  width: 25%; }
  @media only screen and (max-width: 768px) {
    .images__item {
      width: 50%; } }
  .images__item img {
    display: block;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto; }

.form {
  background-repeat: no-repeat;
  background-image: url(../img/form-bg.jpg);
  background-size: cover;
  background-color: #fff;
  color: #fff;
  padding: 35px 0; }
  @media only screen and (max-width: 1050px) {
    .form {
      background-position-x: -50px; } }
  @media only screen and (max-width: 1000px) {
    .form {
      background-position-x: -110px; } }
  @media only screen and (max-width: 940px) {
    .form {
      background-position-x: -130px; } }
  @media only screen and (max-width: 768px) {
    .form {
      background-position-x: calc(100% + 100px); } }
  .form__block {
    border-radius: 10px;
    -webkit-box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 3px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
    padding: 35px 30px 30px;
    max-width: 360px;
    margin-left: auto; }
    @media only screen and (max-width: 768px) {
      .form__block {
        margin: 0 auto; } }
    @media only screen and (max-width: 480px) {
      .form__block {
        padding: 17.5px 15px 20px; } }
  .form__title {
    font-size: 28px !important;
    margin-bottom: 0 !important;
    color: #531230 !important; }
  .form .container {
    position: relative;
    z-index: 1; }
  .form form {
    display: block; }
    .form form .input-wrapper {
      position: relative;
      width: 100%; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper:last-child {
          margin-top: 15px; } }
    .form form .input-wrapper .floating-label {
      position: absolute;
      pointer-events: none;
      top: 30px;
      left: 0;
      -webkit-transition: 0.2s ease all;
      -o-transition: 0.2s ease all;
      transition: 0.2s ease all;
      font-family: "Open Sans Regular";
      font-size: 16px;
      color: #212121; }
      @media only screen and (max-width: 480px) {
        .form form .input-wrapper .floating-label {
          font-size: 14px;
          line-height: 38px; } }
    .form form .input-wrapper input:focus ~ .floating-label,
    .form form .input-wrapper input:not(:focus):valid ~ .floating-label {
      top: 0;
      font-size: 14px; }
    .form form .inputs-wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; }
      @media only screen and (max-width: 480px) {
        .form form .inputs-wrapper {
          -ms-flex-wrap: wrap;
              flex-wrap: wrap; } }
    .form form input {
      display: block;
      width: 100%; }
      .form form input:not([type="submit"]) {
        height: 70px;
        background-color: transparent;
        border-bottom: 1px solid #212121;
        font-family: "Futura PT Bold";
        font-size: 25px;
        line-height: 40px;
        color: #212121;
        padding-top: 25px; }
        @media only screen and (max-width: 480px) {
          .form form input:not([type="submit"]) {
            padding-top: 15px;
            height: 75px; } }
      .form form input[type="submit"] {
        width: 260px;
        height: 50px;
        border-radius: 25px;
        -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #c73540;
        font-size: 20px;
        color: #fff;
        font-family: "Open Sans Regular";
        cursor: pointer;
        margin: 30px auto 0; }
        @media only screen and (max-width: 600px) {
          .form form input[type="submit"] {
            margin: 15px auto 0; } }
        @media only screen and (max-width: 341px) {
          .form form input[type="submit"] {
            max-width: 100%; } }
      .form form input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0; }
    .form form .color--wrapper {
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 40px;
      color: #212121;
      margin: 15px auto 0;
      text-align: center;
      max-width: 222px; }
      @media only screen and (max-width: 480px) {
        .form form .color--wrapper {
          font-size: 13px;
          line-height: 20px; } }
      .form form .color--wrapper div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-top: 10px; }
      .form form .color--wrapper .color-item {
        display: block;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        cursor: pointer; }
        .form form .color--wrapper .color-item-name {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 13px;
          background-color: #fff;
          top: 0px;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          border: 1px solid #000;
          line-height: 14px;
          padding: 3px;
          border-radius: 10px;
          opacity: 0;
          -webkit-transition: opacity .3s;
          -o-transition: opacity .3s;
          transition: opacity .3s; }
          .form form .color--wrapper .color-item-name::after {
            content: " ";
            display: block;
            width: 7px;
            height: 7px;
            position: absolute;
            left: 50%;
            bottom: -5px;
            -webkit-transform: translateX(-50%) rotate(-45deg);
                -ms-transform: translateX(-50%) rotate(-45deg);
                    transform: translateX(-50%) rotate(-45deg);
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            background-color: #fff; }
        .form form .color--wrapper .color-item:hover .color-item-name {
          opacity: 1; }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            padding: 5px 0 5px 30px; } }
        @media only screen and (max-width: 480px) {
          .form form .color--wrapper .color-item {
            width: 50px;
            height: 50px; } }
        .form form .color--wrapper .color-item:first-child {
          background-color: #FEDCDB; }
        .form form .color--wrapper .color-item:nth-child(2) {
          background-color: #E0E0E6; }
        .form form .color--wrapper .color-item:nth-child(3) {
          background-color: #B81B20; }
        .form form .color--wrapper .color-item input:checked ~ .radioBtn {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translateX(-50%) translateY(-50%);
              -ms-transform: translateX(-50%) translateY(-50%);
                  transform: translateX(-50%) translateY(-50%);
          width: 15px;
          height: 15px;
          border-radius: 50%;
          -webkit-box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.69);
                  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.69);
          background-color: #ffffff; }
          @media only screen and (max-width: 480px) {
            .form form .color--wrapper .color-item input:checked ~ .radioBtn {
              width: 10px;
              height: 10px; } }
  .form__price {
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 24px;
    position: relative;
    left: -30px;
    background-color: #fee4e4;
    width: calc(100% + 60px);
    padding: 15px 20px;
    text-align: center; }
    @media only screen and (max-width: 768px) {
      .form__price {
        margin: 20px auto 0; } }
    @media only screen and (max-width: 480px) {
      .form__price {
        font-size: 14px;
        line-height: 22px;
        left: -15px;
        width: calc(100% + 30px);
        padding: 10px 25px; } }
    .form__price .old, .form__price .new {
      color: rgba(83, 18, 48, 0.6); }
      .form__price .old span, .form__price .new span {
        font-size: 30px;
        line-height: 40px;
        display: inline-block;
        color: #212121;
        margin-top: 20px; }
        @media only screen and (max-width: 480px) {
          .form__price .old span, .form__price .new span {
            margin-top: 10px; } }
        @media only screen and (max-width: 374px) {
          .form__price .old span, .form__price .new span {
            font-size: 29px;
            line-height: initial; } }
    .form__price .old span {
      font-family: "Futura PT Light";
      position: relative; }
      .form__price .old span::after {
        content: " ";
        display: block;
        position: absolute;
        left: 0;
        top: 22px;
        height: 2px;
        width: 100%;
        background-color: #f75252; }
    .form__price .new {
      text-align: right; }
      .form__price .new span {
        font-family: "Futura PT Bold";
        color: #531230; }
        @media only screen and (max-width: 341px) {
          .form__price .new span {
            font-size: 26px; } }

.advantages {
  background-color: #72494b;
  color: #fff;
  padding: 40px 0; }
  .advantages .container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .advantages .container {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; } }
  .advantages__item {
    width: calc(33.33334% - 30px);
    padding-left: 120px;
    position: relative;
    background-repeat: no-repeat; }
    @media only screen and (max-width: 768px) {
      .advantages__item {
        width: 100%;
        padding-left: 90px; } }
    .advantages__item:first-child {
      background-image: url(../img/delivery-icon.svg);
      background-position: 20px 35px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:first-child {
          background-position: 12px 23px;
          background-size: 55px; } }
    .advantages__item:nth-child(2) {
      background-image: url(../img/wallet-icon.svg);
      background-position: 27px 23px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:nth-child(2) {
          background-position: 17px 13px;
          background-size: 44px;
          margin-top: 20px; } }
    .advantages__item:last-child {
      background-image: url(../img/package-icon.svg);
      background-position: 32px 28px; }
      @media only screen and (max-width: 768px) {
        .advantages__item:last-child {
          background-position: 20px 16px;
          margin-top: 20px; } }
    .advantages__item::before {
      content: " ";
      display: block;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: solid 2px rgba(255, 255, 255, 0.4);
      position: absolute;
      left: 0;
      top: 0; }
      @media only screen and (max-width: 768px) {
        .advantages__item::before {
          width: 75px;
          height: 75px; } }
    .advantages__item-title {
      font-family: "Open Sans Regular";
      font-size: 24px;
      line-height: 40px; }
      @media only screen and (max-width: 480px) {
        .advantages__item-title {
          font-size: 21px;
          line-height: 38px; } }
    .advantages__item-description {
      margin-top: 10px;
      font-family: "Open Sans Regular";
      font-size: 16px;
      line-height: 22px; }
      @media only screen and (max-width: 480px) {
        .advantages__item-description {
          font-size: 14px;
          line-height: 20px; } }

.footer {
  background-color: #72494b;
  padding: 15px 0;
  color: #fff;
  text-align: center;
  font-family: "Open Sans Regular";
  font-size: 16px;
  line-height: 22px; }
  .footer a {
    text-decoration: underline; }
    .footer a:hover {
      text-decoration: none; }

body {
  overflow-x: hidden; }

.container {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 15px; }

.section-title {
  font-family: "Futura PT Book";
  font-size: 48px;
  line-height: 72px;
  color: #212121;
  text-align: center;
  margin-bottom: 75px; }
  @media only screen and (max-width: 768px) {
    .section-title {
      font-size: 35px;
      line-height: 50px; } }
  @media only screen and (max-width: 480px) {
    .section-title {
      font-size: 22px;
      line-height: 30px;
      margin-bottom: 35px; } }

.price__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 855px;
  min-height: 115px;
  border-radius: 60px;
  margin: 20px auto 0;
  background-color: #ffe5e5; }
  @media only screen and (max-width: 749px) {
    .price__row {
      padding: 15px; } }
  @media only screen and (max-width: 480px) {
    .price__row {
      margin: 30px auto 0;
      padding: 7px 5px;
      min-height: initial; } }
  @media only screen and (max-width: 360px) {
    .price__row {
      padding: 5px;
      border-radius: 25px; } }
  .price__row-block {
    max-width: 404px;
    height: 100px;
    border-radius: 50px;
    color: #531230;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    text-align: center; }
    @media only screen and (max-width: 600px) {
      .price__row-block {
        width: calc(50% - 5px);
        margin: 0 auto; } }
    @media only screen and (max-width: 480px) {
      .price__row-block {
        height: 50px; } }
    .price__row-block .old, .price__row-block .new {
      font-family: "Open Sans Light";
      font-size: 20px;
      line-height: 24px; }
      @media only screen and (max-width: 477px) {
        .price__row-block .old, .price__row-block .new {
          font-size: 14px; } }
      @media only screen and (max-width: 360px) {
        .price__row-block .old, .price__row-block .new {
          font-size: 14px;
          line-height: 22px; } }
      .price__row-block .old span, .price__row-block .new span {
        font-size: 30px;
        line-height: 40px;
        color: #531230; }
        @media only screen and (max-width: 576px) {
          .price__row-block .old span, .price__row-block .new span {
            font-size: 25px; } }
        @media only screen and (max-width: 480px) {
          .price__row-block .old span, .price__row-block .new span {
            font-size: 23px; } }
        @media only screen and (max-width: 374px) {
          .price__row-block .old span, .price__row-block .new span {
            font-size: 16px; } }
    .price__row-block .old {
      padding: 0 20px 0 40px; }
      @media only screen and (max-width: 400px) {
        .price__row-block .old {
          padding: 0 10px 0 30px; } }
      @media only screen and (max-width: 360px) {
        .price__row-block .old {
          padding: 0 10px 0 20px; } }
      .price__row-block .old span {
        display: inline-block;
        position: relative;
        font-family: "Futura PT Light"; }
        .price__row-block .old span::after {
          content: " ";
          display: block;
          height: 2px;
          width: 100%;
          position: absolute;
          top: 50%;
          -webkit-transform: translateY(-50%);
              -ms-transform: translateY(-50%);
                  transform: translateY(-50%);
          left: 0;
          background-color: #f75252; }
    .price__row-block .new {
      padding: 0 20px; }
      @media only screen and (max-width: 480px) {
        .price__row-block .new {
          padding: 0 10px; } }
      .price__row-block .new span {
        font-family: "Futura PT Bold"; }
  .price__row .price__order {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 260px;
    height: 50px;
    border-radius: 25px;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
    background-color: #c73540;
    color: #fff;
    font-family: "Open Sans Regular";
    font-size: 20px;
    line-height: 40px; }
    @media only screen and (max-width: 600px) {
      .price__row .price__order {
        width: calc(50% - 5px);
        margin-left: 10px; } }
    @media only screen and (max-width: 480px) {
      .price__row .price__order {
        font-size: 17px; } }
    @media only screen and (max-width: 374px) {
      .price__row .price__order {
        font-size: 16px; } }
