.footer {
    background-color: #72494b;
    padding: 15px 0;
    color: #fff;
    text-align: center;
    font-family: $fontOpenSansRegular;
    font-size: 16px;
    line-height: 22px;
    a {
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}