@import 'normalize';

@import 'variebles';

@import 'fonts';
@import 'responce';
@import 'header';
@import 'home';
@import 'footer';

body {
    overflow-x: hidden;
}

.container {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    padding: 0 15px;
}

.section-title {
    font-family: $fontFuturaBook;
    font-size: 48px;
    line-height: 72px;
    color: #212121;
    text-align: center;
    margin-bottom: 75px;
    @include mediaw(768px) {
        font-size: 35px;
        line-height: 50px;
    }
    @include mediaw(480px) {
        font-size: 22px;
        line-height: 30px;
        margin-bottom: 35px;
    }
}

.price__row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 855px;
    min-height: 115px;
    border-radius: 60px;
    margin: 20px auto 0;
    background-color: #ffe5e5;
    @include mediaw(749px) {
        padding: 15px;
    }
    @include mediaw(480px) {
        margin: 30px auto 0;
        padding: 7px 5px;
        min-height: initial;
    }
    @include mediaw(360px) {
        padding: 5px;
        border-radius: 25px;
    }
    &-block {
        max-width: 404px;
        height: 100px;
        border-radius: 50px;
        color: #531230;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        @include mediaw(600px) {
            width: calc(50% - 5px);
            margin: 0 auto;
        }
        @include mediaw(480px) {
            height: 50px;
        }
        .old, .new {
            font-family: $fontOpenSansLight;
            font-size: 20px;
            line-height: 24px;
            @include mediaw(477px) {
                font-size: 14px;
            }
            @include mediaw(360px) {
                font-size: 14px;
                line-height: 22px;
            }
            span {
                font-size: 30px;
                line-height: 40px;
                color: #531230;
                @include mediaw(576px) {
                    font-size: 25px;
                }
                @include mediaw(480px) {
                    font-size: 23px;
                }
                @include mediaw(374px) {
                    font-size: 16px;
                }
            }
        }
        .old {
            padding: 0 20px 0 40px;
            @include mediaw(400px) {
                padding: 0 10px 0 30px;
            }
            @include mediaw(360px) {
                padding: 0 10px 0 20px;
            }
            span {
                display: inline-block;
                position: relative;
                font-family: $fontFuturaLight;
                &::after {
                    content: " ";
                    display: block;
                    height: 2px;
                    width: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 0;
                    background-color: #f75252;
                }
            }
        }
        .new {
            padding: 0 20px;
            @include mediaw(480px) {
                padding: 0 10px;
            }
            span {
                font-family: $fontFuturaBold;
            }
        }
    }
    .price__order {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 260px;
        height: 50px;
        border-radius: 25px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.16);
        background-color: #c73540;
        color: #fff;
        font-family: $fontOpenSansRegular;
        font-size: 20px;
        line-height: 40px;
        @include mediaw(600px) {
            width: calc(50% - 5px);
            margin-left: 10px;
        }
        @include mediaw(480px) {
            font-size: 17px;
        }
        @include mediaw(374px) {
            font-size: 16px;
        }
    }
}